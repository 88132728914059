@media print {

  .border-black, .border-black *{
    border: 2px solid black !important;
  }
  
  body *{
    visibility: hidden;
  }

  #claimformBill-print-container , #claimformBill-print-container  *{
    visibility: visible !important;
    zoom: 1.25;
    margin-top: -500px;
  }


  
}
.hide{
  display: none;
}

.border-black{
  border: 2px solid black !important;
}