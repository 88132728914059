@media print {
  body * {
    visibility: hidden;
    transform: scale(1);
  }

  .claimform-print-container,
  .claimform-print-container * {
    visibility: visible;
    padding: 0;
    margin: 0;
    zoom: 1.07;
    margin-top: 5px;
  }

  #labresultprint table, #labresultprint th, #labresultprint tr,#labresultprint  td {
    border: 2px solid black;
  }

}


.hide{
  display: none;
}

#claim-form label{
  font-weight: bold;
}

.large-box{
  border: 2px solid rgb(68, 68, 68);
  border-radius: 10px;
  padding: 30px;
}

.bottom-border{
  border-bottom: 2px solid rgb(68, 68, 68);
  margin-bottom: 7px;
}