
.tablexflow {
  overflow-x: scroll;
  color: black;
}

.pd {
  padding-bottom: "25px";
}

.closebox {
  color: rgb(233, 231, 231);
  /* font-weight: bold; */
  cursor: pointer;
  margin-left: 1px;
}

.bg-yellow {
  background-color: #ffbb00;
}

.c > ul {
  background-color: #ffbb00;
}
.remove {
  background-color: transparent;
  color: white;
  border-color: transparent;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 100px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(15, 5, 104);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.sidebar-icons {
  margin-top: 5px;
  margin-right: 10px;
  font-size: 20px;
}

.modal-backdrop {
  height: 100%;
  width: 100%;
}

.chat-box .chat-form textarea {
  overflow: hidden;
  border-top: 1px solid #eee !important;
  font-size: 15px !important;
  padding-right: 95px !important;
}

.attach-icon {
  background-color: transparent;
  padding: 0;
  right: 40px !important;
  box-shadow: none !important;
}
.attach-icon i {
  font-size: 20px;
}

.attach-box {
  position: absolute;
  right: 40px;
  bottom: 0 !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 30px 0 30px 30px;
}
.attach-box .btn {
  position: static !important;
  margin-bottom: 5px !important;
  margin-right: 0 !important;
  right: 0 !important;
}

.chat-text img {
  max-height: 360px;
}
.chat-left .chat-text a {
  color: black;
}
.chat-right .chat-text a {
  color: white;
}
.chat-content {
  height: 72vh !important;
  overflow: auto !important;
  display: none;
}

.people-list .about {
  width: calc(100% - 45px);
  box-sizing: border-box;
}
.people-list .about .head {
  display: flex;
  justify-content: space-between;
}

.composeForm textarea {
  height: 130px !important;
}

.checkbox-list input[type='checkbox'] {
  zoom: 1.5;
}
.checkbox-list {
  max-height: 80vh;
  overflow: auto;
}
.check-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.check-row label{
  margin-bottom: 0;
  margin-left: 5px;
}

.chip {
  display: inline-block;
  padding: 0 25px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 25px;
  background-color: #f1f1f1;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chip img {
  float: left;
  margin: 0 10px 0 -20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.chip i {
  color: red;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#chat-btn {
  position: fixed;
  right: 20px;
  bottom: 180px;
  z-index: 10;
  color: white;
  background-color: #6777ef;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
#chat-btn i {
  font-size: 25px;
}
#chat-btn span {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  padding: 2px 10px;
  border-radius: 10px;
}

@keyframes slide {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-900px, 0, 0); /* The image width */
  }
}

.scroll-div {
  animation: slide 15s linear infinite;
}
.notification-flow-control{
  overflow-y: scroll !important; 
  height: 392px !important;
  width: 298px !important;
}
#popup{
  display: none !important;
}
.report-serach .input-group input{
  font-size: 18px !important;
  height: 35px !important;
  width: 320px;
}
.report-serach .input-group-btn button{
  height: 100%;
  top: 1px;
}
.filter-btn-box {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: fit-content;
}
.table-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}
table.report tr td p {
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  color: inherit !important;
}
table.td-nowap tr td {
  white-space: nowrap;
}
.table.report td, .table.report th {
  border-color: #dddddd;
}
.table-pagination ul.pagination {flex-wrap: wrap;}
table.report tr th:nth-child(5n + 1) { color: brown; }
table.report tr th:nth-child(5n + 2) { color: red; }
table.report tr th:nth-child(5n + 3) { color: blue; }
table.report tr th:nth-child(5n + 4) { color: green; }
table.report tr th:nth-child(5n + 5) { color: deeppink; }
/* div.table-responsive:has(table.report)::-webkit-scrollbar {
  
} */



.PhoneInputInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none; 
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}