@media print {
  body * {
    visibility: hidden;
    transform: scale(1.1);
  }

  .qrcode-print-container,
  .qrcode-print-container * {
    visibility: visible;
    zoom: 1.3;
    text-align: center;
  }

  .mt-top-print {
    margin-top: 250px;
    /* margin-left: 140px; */
  }
}


.hide{
  display: none;
}